import React from "react";
import { graphql, PageProps } from "gatsby";

import { Query, SanityCampaignPageConnection } from "@graphql-types";
import { Components } from "@components";
import SEO from "@shared/seo";
import { Container, GiveMeSomeSpace } from "@util/standard";
import Hero from "@shared/hero";
import { ProviderContainer } from "@global";
import { useStore } from "@state/store";

interface Data extends Query {
  altRegions: SanityCampaignPageConnection;
}

interface Props extends PageProps {
  data: Data;
}

export default function CampaignTemplate({ data, pageContext }: Props) {
  const { user } = useStore();
  const { nodes } = data.allSanityCampaignPage;

  const {
    password,
    components,
    hero,
    seo,
    hideHero,
  } = nodes[0];
  const alternateRegions = data.altRegions.nodes.map(alt => alt.region);

  return (
    <div>
      <SEO seoData={seo} alternateRegions={alternateRegions} heroData={hero} slug={pageContext?.pagePath} />
      <ProviderContainer password={password} isAdmin={user?.isAdmin}>
        {hideHero !== true ?
          <Hero heroData={hero} isFullscreen />
          :
          <GiveMeSomeSpace space={40} />
        }
        {components &&
          <Container flexDirection="column">
            {components.map(component => {
              if (component == null) return null;
              return (
                <Components key={component._key} data={component} />
              );
            })
            }
          </Container>
        }
      </ProviderContainer>
    </div>
  );
}

export const query = graphql`
  query CampaignQuery($region: String, $slug: String) {
    allSanityCampaignPage(filter: { 
      region: { iban: { eq: $region } } 
      slug: { current: { eq: $slug } }
    }) {
      nodes {
        password
        title
        hideHero
        region {
          ...sanityRegion
        }
        seo {
          ...sanitySeo
        }
        hero {
          ...sanityHero
        }
        components {
          ... on SanityAccordionBlock {
            ...sanityAccordionBlock
          }
          ... on SanityCalculatorBlock {
            ...sanityCalculatorBlock
          }
          ... on SanityFeaturedBlogsBlock {
            ...sanityFeaturedBlogsBlock
          }
          ... on SanityFullScreenContentBlock {
            ...sanityFullScreenContentBlock
          }
          ... on SanityImageContentCarouselBlock {
            ...sanityImageContentCarouselBlock
          }
          ... on SanityImageSliderBlock {
            ...sanityImageSliderBlock
          }
          ... on SanityImagesContentBlock {
            ...sanityImagesContentBlock
          }
          ... on SanityInfoBlock {
            ...sanityInfoBlock
          }
          ... on SanityMediaContentBlock {
            ...sanityMediaContentBlock
          }
          ... on SanityNewsletterBlock {
            ...sanityNewsletterBlock
          }
          ... on SanityPartnersBlock {
            ...sanityPartnersBlock
          }
          ... on SanityStatsBlock {
            ...sanityStatsBlock
          }
          ... on SanityStepsBlock {
            ...sanityStepsBlock
          }
          ... on SanityVideoSliderBlock {
            ...sanityVideoSliderBlock
          }
          ... on SanityGetStartedBlock {
            ...sanityGetStartedBlock
          }
        }
      }
    }
    altRegions: allSanityCampaignPage {
      nodes {
        region {
          ...sanityRegion
        }
      }
    }
  }
`;
